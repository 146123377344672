/*
    ===============================
    /|\                         /|\
    /|\                         /|\
    /|\    Analytics Section    /|\
    /|\                         /|\
    /|\                         /|\
    ===============================
*/


@use './_widgets/reset-analytics';
@use './_widgets/widget-card-four';
@use './_widgets/widget-six';
@use './_widgets/widget-chart-three';
@use './_widgets/widget-activity-five';

@use './_widgets/widget-hybrid-one';
@use './_widgets/widget-card-three';
@use './_widgets/widget-card-one';
@use './_widgets/widget-four';
@use './_widgets/widget-card-two';
@use './_widgets/widget-five';